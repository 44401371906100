const codes = {
  anguilla: 'ai',
antiguaAndBarbuda: 'ag',
aruba: 'aw',
bahamas: 'bs',
bahrain: 'bh',
barbados: 'bb',
belize: 'bz',
bermuda: 'bm',
britishVirginIslands: 'vg',
caboVerde: 'cv',
caymanIslands: 'ky',
comoros: 'km',
cookIslands: 'ck',
cuba: 'cu',
curacao: 'cw',
dominica: 'dm',
dominicanRepublic: 'do',
fiji: 'fj',
grenada: 'gd',
guineaBissau: 'gw',
guyana: 'gy',
haiti: 'ht',
jamaica: 'jm',
kiribati: 'ki',
kittsAndNevis: 'kn',
maldives: 'mv',
marshallIslands: 'mh',
mauritius: 'mu',
micronesia: 'fm',
montserrat: 'ms',
nauru: 'nr',
niue: 'nu',
palau: 'pw',
papuaNewGuinea: 'pg',
saintLucia: 'lc',
samoa: 'ws',
saoTomeAndPrincipe: 'st',
seychelles: 'sc',
singapore: 'sg',
sintMaarten: 'sx',
solomonIslands: 'sb',
stVincent: 'vc',
suriname: 'sr',
timorLeste: 'tl',
tokelau: 'tk',
tonga: 'to',
trinidadAndTobago: 'tt',
turksAndCaicos: 'tc',
tuvalu: 'tv',
vanuatu: 'vu'
}

export default codes
